<template>
  <img class="mx-auto p-4 max-w-[200px]" alt="Vue logo" src="./assets/CTL_Logo.png">

  <div class="w-11/12 mx-auto my-4 text-center">
    <div><span class="underline">Ihr Ansprechpartner:</span><br><h3 class="text-lg font-semibold">Ariane<br>Habel</h3></div>
    <a href="tel:016097539963"><button class="m-4 p-4 rounded md:rounded-lg bg-imupro text-white">Jetzt kontaktieren!</button></a>
  </div>

  <div class="w-11/12 mx-auto mb-8">
    <div class="text-center px-4 pt-6 underline">Hier können Sie sich gerne weiterführend informieren:</div>
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
      <a id='imupro' :class='{indivskewloaded: imuproinviewport}' class="indivskew shadow-lg shadow-imupro origin-bottom bg-gray w-full h-96 p-12 relative border-[1px] border-solid border-white rounded-2xl" href="https://www.imupro.de">
        <div class="absolute w-full top-1/2 left-0 -translate-y-1/2" >
          <img class="mx-auto max-h-80 max-w-full p-4" alt="Vue logo" src="./assets/imupro_logo.png">
          <div class="text-center p-4">Hier geht es zu allen Infos zum Imupro.</div>
        </div>
      </a>
      <a id='ctl1' :class='{indivskewloaded: ctl1inviewport}' class="indivskew shadow-lg shadow-imupro origin-bottom bg-gray w-full h-96 p-12 relative border-[1px] border-solid border-white rounded-2xl col-span-1 md:col-span-2 xl:col-span-1 hidden xl:block" href="https://www.ctl-labor.de">
        <div class="absolute w-full top-1/2 left-0 -translate-y-1/2" >
          <img class="mx-auto max-h-80 max-w-full p-4" alt="Vue logo" src="./assets/CTL_Logo.png">
          <div class="text-center p-4">Besuchen Sie die Webseite unseres Labors.</div>
        </div>
      </a>
      <a id='neurospot' :class='{indivskewloaded: neurospotnviewport}' class="indivskew shadow-lg shadow-imupro origin-bottom bg-gray w-full h-96 p-12 relative border-[1px] border-solid border-white rounded-2xl" href="https://www.ctl-labor.de/patient/neurospot/">
        <div class="absolute w-full top-1/2 left-0 -translate-y-1/2" >
          <img class="mx-auto max-h-80 max-w-full p-4" alt="Vue logo" src="./assets/neurospot.png">
          <div class="text-center p-4">Der hormonelle Stresstest. Hier mehr zum Neurospot erfahren.</div>
        </div>
      </a>
      <a id='ctl2' :class='{indivskewloaded: ctl2inviewport}' class="indivskew shadow-lg shadow-imupro origin-bottom bg-gray w-full h-96 p-12 relative border-[1px] border-solid border-white rounded-2xl col-span-1 md:col-span-2 xl:hidden" href="https://www.ctl-labor.de">
        <div class="absolute w-full top-1/2 left-0 -translate-y-1/2" >
          <img class="mx-auto max-h-80 max-w-full p-4" alt="Vue logo" src="./assets/CTL_Logo.png">
          <div class="text-center p-4">Besuchen Sie die Webseite unseres Labors.</div>
        </div>
      </a>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'App',
  mounted: function(){
    let self = this;
    setTimeout(() => {
      self.handleScroll();
    }, 500);

    document.addEventListener('scroll', this.handleScroll)
  }, 
  unmounted: function() {
    document.removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      ctl1inviewport: false,
      ctl2inviewport: false,
      neurospotnviewport: false,
      imuproinviewport: false,
    };
  },
  methods: {
    handleScroll(){
      const el1 = document.getElementById('ctl1');
      if(this.elementInViewport(el1)) {
        this.ctl1inviewport = true;
      } else {
        this.ctl1inviewport = false;
      }
      const el2 = document.getElementById('ctl2');
      if(this.elementInViewport(el2)) {
        this.ctl2inviewport = true;
      } else {
        this.ctl2inviewport = false;
      }
      const el3 = document.getElementById('imupro');
      if(this.elementInViewport(el3)) {
        this.imuproinviewport = true;
      } else {
        this.imuproinviewport = false;
      }
      const el4 = document.getElementById('neurospot');
      if(this.elementInViewport(el4)) {
        this.neurospotnviewport = true;
      } else {
        this.neurospotnviewport = false;
      }
    },
    elementInViewport(el){
      var top = el.offsetTop;
      var height = el.offsetHeight;

      while(el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
      }

      return (
        top + height / 2 >= window.pageYOffset && (top + height / 2) <= (window.pageYOffset + window.innerHeight)
      );
    }
  }
}
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

.indivskew {
  transform:
    perspective(400px)
    rotateX(90deg);
  transition: transform 0.5s ease-in-out 0s;
}
.indivskewloaded {
  transform:
    perspective(400px)
    rotateX(5deg);
}

</style>
